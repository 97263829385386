.navWrapper{
        background-color: #fff;
        width: 100%;
        position: sticky;
        top: 0%;
        z-index: 1000;
}

.logo-base{
        color: var(--main-color);
        font-size: 2rem;
        font-weight: 500;
        font-family: 'Montserrat';
}

.navMobileFix {
        background-color: #fff;
        padding-bottom: 1rem;
}
.burger{
        padding-top: .2rem;
        padding-right: .5rem;
}
.mobile-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.nav-icon{
        font-size: 1.5rem;
        margin: .4rem;
        color: #172B4D;
}
.shoping_cart_mobile.nav-icon {
        position: relative;
}
.shoping_cart_mobile .mobile_cart_count{
        position: absolute;
        top: 0;
        right: 0;
        width: 1.4rem;
        height: 1.4rem;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.87rem;
        color: #fff;
        border-radius: 50%;
        transform: translate(70%, -70%);
}
.user_search_wrapper {
        position: relative;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
}
.search{
        width: 100%;
        margin-block: .3rem;
        background-color: #f0f0f0;
        height: 2.2rem;
        position: relative;
        border-radius: 20px;
}
.user_search input::placeholder{
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
}
.user_search input{
        position: absolute;
        width: calc(100%);
        height: 100%;
        border-radius: 20px;
        background-color: transparent;
        padding-left: 1rem;
        text-transform: capitalize;
        border: none;
}
.user_search .ais-SearchBox-form {
        margin: 0px;
}
.user_search .ais-SearchBox-submit,
.user_search .ais-SearchBox-reset{
        display: none;
}
.user_search_wrapper .searchIcon{
        position: absolute;
        top: 0%;
        right: 0%;
        height: 100%;
        width: 2.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;

        /*
        top: 50%;
        transform: translate(-50%, -25%);
        margin-right: 1.5rem; */
        background-color: var(--main-color);
        color: #fff;
}
/* .user_search_wrapper{
        /* border-radius:  50%;
} */
.search_result_list:hover .result_desc p {
        color: rgb(86, 157, 204);
        transition: all .3s ease-in-out;
}

.nav-mobile{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        z-index: 10000;
}
.showNav{
        transition: all .3s ease-in-out;
        transform: translateX(0%);
}
.nav-content {
        width: 80%;
        background-color: #fff;
        height: 100%;
        overflow-y: scroll;
}
.nav-content::-webkit-scrollbar{
        display: none;
}
.nav-mobile .shadow{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: all .15s ease-in-out .22s;
}
.nav-mobile .showShadow {
        opacity: .8;
}
.nav-inside-logo{
        padding: .7rem;
        margin-bottom: 0.7rem;
        border-bottom: 1px solid #f0f0f0;
}
.nav-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.nav-head .icon{
        font-size: 1.4rem;
        margin-top: .7rem;
        margin-right: 1rem;
}


.nav-list{
        margin-left: 1rem;
}
.navLinks{
        margin: 0;
        padding: 0;
}
.navLinks li{
        margin-top: .15rem;
}
.navLink{
        display: flex;
        padding-block: .2rem;
        align-items: center;
        font-size: .9rem;
}
.lang_globe {
        margin-right: .5rem;
        padding-top: .3rem;
}
.shareOn{
        font-size: .78rem;
        margin-left: 2rem;
}
.shareLinks{
        transition: .4s ease-in-out;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: .5rem;
        margin-right: 1.5rem;
        background-color: rgb(245, 241, 245);
        border-radius: 10px;


}
.shareIcon{
        font-size: 1.7rem;
        margin: 0 0.2rem;
        color: #000080;
        border: 1px solid #000080;
        border-radius: 100px;
        padding: .1rem;
        background-color: #f5f5ff;
}
.logoutNavLink {
        color: rgb(245, 48, 87);
}
.logNavLink {
        color: rgb(8, 161, 115);
}
.mobile-slide-right {
        position: relative;
        margin-left: 1rem;
}
.logoutNavLink .accountIcon {
        margin-right: 1rem;
        padding-top: .35rem;
}
.navLink .drop_cat_list {
        width: 100%;
}
.mobile-slide-right-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        height: 100%;
        transition: all .3s ease-in-out;
        transform: translateX(-110%);
        box-shadow: 2px -1px 2px #0c000011;
}
.mobile-slide-right-item .dropdownNav-item {
        padding-block: .3rem;
        margin-bottom: .3rem;
}
.lang_slide_nav {
        /* padding-block: 1rem; */
        /* padding-left: 1rem; */
        width: 100%;
}
.remove_slide {
        padding-left: 2rem;
}
.show_sub_nav_lang {
        transform: translateX(0);
}

/* =====================LINK IMAGE================= */
.imageWrapper{
        width: 2rem;
        height: 2.2rem;
        background-color: #f5f5ff;
        border-radius: 2px;
        margin-right: 15px;
        border: 1px solid #e4e4e4;
}
.navLinkImage{
        height: 90%;
        border-radius: 2px;
}
.navLink .imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
}

.switch_to_selling_btn{
        padding: .7rem;
        font-size: 0.9rem;
        background: #055C9D;
        border-radius: 5px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
}
.switch_icon_group{
        display: flex;
        justify-content: center;
        align-items: center;
}
.switch_to_selling_btn .switch_font{
        font-weight: 400;
}
@media(min-width: 60rem){
        .navMobile{
                display: none;
        }
}
@media(min-width: 961px) and  (max-width: 970px ){
        .responsive_btn {
                display: none;
                transition: 0.3s ease-in;
        }
}
