.settings-coming-soon {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .settings-content {
    text-align: center;
    max-width: 32rem;
  }
  
  .settings-icon {
    margin-bottom: 2rem;
    animation: pulse 2s infinite ease-in-out;
  }
  
  .settings-icon svg {
    margin: 0 auto;
    color: #3b82f6; /* Bleu */
  }
  
  .settings-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .settings-message {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .message-primary {
    font-size: 1.125rem;
    color: #4b5563;
  }
  
  .message-secondary {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Responsive */
  @media (max-width: 640px) {
    .settings-title {
      font-size: 1.5rem;
    }
    
    .message-primary {
      font-size: 1rem;
    }
    
    .message-secondary {
      font-size: 0.875rem;
    }
    
    .settings-icon svg {
      width: 48px;
      height: 48px;
    }
  }