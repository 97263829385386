/* The Modal (background) */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    scroll-behavior: smooth;

}
.discount-page-title{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 25px 0px 0px 0px;
}
.discount-page-subTitle {
    font-size: 0.9rem;
    font-weight: 300;
    margin-left: 40px;
    font-family: "Montserrat", sans-serif;
}

.discount-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

/* Modal Content */
.modal-content {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 4px;
    max-width: 500px;
    max-height: 80vh; /* Limit height to make it scrollable if content overflows */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}
.modal-content::-webkit-scrollbar {
    display: none;
}
.discount-heading{
    display: flex;
    justify-content: space-between; /* Pushes elements to both ends */
    align-items: center; /* Aligns items vertically */
    width: 100%; /* Ensure it takes full width */
    background-color: #ececec;
    padding: 0 10px;
    border-radius: 5px;
}
.discount-heading span{
    font-weight: 400;
}
.discount-form label{
    display: flex;
    flex-direction: column;
}
.discount-form label select{
    font-weight: 400;
}
/* Close Button */
.close {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 0;
}

.discount_btns {
    display: flex;
    justify-content: space-between;
}

.discount_btns .cancel {
    margin-top: 16px;
    margin-right: 16px;
    padding: 8px 16px;
    background-color: #cf0101;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    &:hover{
        background-color: #a40000;
        transition: all .2s ease-in;
    }
}
.discount_btns .cancel:hover {
    background-color: #c50024;
    transition: all 0.2s ease-in-out;
    color: white;
}
.close:hover,
.close:focus {
    color: #011b33;
    text-decoration: none;
    cursor: pointer;
}

/* Responsive */
@media (max-width: 768px) {
    .modal-content {
        max-width: 90%;
        width: 90%;
    }
}

@media (max-width: 480px) {
    .modal-content {
        max-width: 100%;
        width: 100%;
        padding: 10px;
        margin-top: 50px;
    }

    .modal {
        max-width: 100vw;
        padding-top: 50px;
        padding-left: 60px;
        padding-right: 20px;
        overflow: hidden;
    }
}


/* table */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.8rem;
    margin-top: 1rem;
}
thead tr {
    border-bottom: 1px solid #ddd;
}
th,
td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}


tr:nth-child(even) {
    background-color: #f5f5f5;
}
tr:hover {
    background-color: #00000009;
    transition: all .3s ease-in;
}
button{
    margin-top: 16px;
    margin-right: 16px;
    padding: 8px 16px;
    background-color: #000080;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.discount-add {
    margin-top: 16px;
    margin-right: 16px;
    padding: 8px 16px;
    background-color: #000080;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.discount-btn-edit{
    margin-top: 16px;
    margin-right: 16px;
    padding: 8px 16px;
    background-color: #01cf72;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    &:hover{
        background-color: #01be69;
        transition: all .2s ease-in;
    }
}

.discount-btn-delete{
    margin-top: 16px;
    margin-right: 16px;
    padding: 8px 16px;
    background-color: #cf0101;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    &:hover{
        background-color: #a40000;
        transition: all .2s ease-in;
    }
}
.discount-add span{
    font-weight: 400;
}
button span{
    font-weight: 400;
}

.discount-add:hover {
    background-color: #000056;
    transition: all .2s ease-in;
    color: white;
}

form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 100%;
}

label {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

input,
select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

input[type="date"] {
    appearance: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

input[type="submit"] {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #46A836;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.mobile_label {
    display: none;
}

.discount_checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-green);
    color: white;
    padding: 8px;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.discount_checkmark.failed {
    background-color: crimson;
}
.discount_modal {
    display: flex;
    gap: 8px;
    align-items: center;
}
.table_wrapper .non_mobile button,
.table_wrapper .cancel {
    background-color: crimson !important;
}
/* Responsive */

/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    .discount-page-subTitle, .discount-page-title{
        text-align: left;
        margin-left: 10px;
        padding: 0;
    }
    /* table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        margin: 0 0 1rem 0;
    }


    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 20px;
    }

    td:before {
        position: absolute;
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
    .mobile_label {
        display: block;
    }
    .non_mobile {
        display: none;
    }
    .mobile_flex {
        display: flex;
        flex-direction: row;
    }

    table {
        border-collapse: collapse;
    }
    
    tr {
        border: 1px solid #ccc;
        padding: 8px;
        border-radius: 5px;
    } */
}

@media(min-width: 60rem){
    .seller_pages_with_nav .table_wrapper {
        overflow-x: hidden;
    }
}