.nav_home {
        background-color: #fff;
        padding-block: 0.4rem;
        border-bottom: 1px solid var(--gray);
        position: relative;
        z-index: 25;
        position: fixed;
        top: 0%;
        right: 0%;
        left: 0%;
        z-index: 100;
}
.nav_home .shadow {
        transition: all .12s ease-in-out;
        width: 0%;
        opacity: 0;
}
.nav_home .showShadow {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        margin-left: calc(96.5% - 100vw);
        z-index: -1;
        opacity: 1;
}

.nav_home .logo-white {
        color: #000080;
}
.nav_home_mobile_head {
         display: flex;
         justify-content: space-between;
         align-items: center;
 }
.nav_home_mobile_head a {
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.nav_home_mobile_head .burger {
        font-size: 1.5rem;
        position: relative;
        z-index: 1000;
}
.nav_home_content {
        position: fixed;
        width: 80%;
        top: 0;
        height: 100vh;
        margin-left: calc(96.5% - 100vw);
        background-color: rgb(243, 247, 255);
        transition: all .1s ease-in-out;
        transform: translateX(-100%);
        opacity: 0;
        z-index: 100;
}
.showNavHome {
        opacity: 1;
        transform: translateX(0%);
}
.nav_home_navLinks {
        padding: 2rem;
        gap: 10px;
}
.nav_home_navLinks li{
        margin-block: 1rem;
}
.nav_home_navLinks li a{
        color: #000;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        text-wrap: nowrap;
}
.nav_home_navLinks .logoutNavLink {
        display: flex;
        align-items: center;
}
.desktop_nav_seller {
        display: none;
}
.dropdownNav_seller {
        position: relative;
}
.desktop {
        display: none;
}
.account_link_ta {
        display: flex;
        align-items: center;
}
.account_link_ta .linkTop{
        font-weight: 500 !important;
        text-transform: capitalize;
}

.dropdown_text_head{
        display: flex;
        justify-content: center;
        align-items: center;
}
.switchToBuyingMsg{
        display: none;
}

/* =================== THE BAR -================ */

.seller_nav_aside {
        position: fixed;
        top: 5rem;
        left: 0;
        width: 3rem;
        box-shadow: 2px 0px 2px rgba(216, 216, 216, 0.445);
        height:  100vh;
        padding-block: 1rem;
        background-color: #fff;
        transition: all .3s ease-in-out;
        z-index: 25;
}
.sidebarScrollPosition{
        /* top: 0; */
        
}
.scale_side_nav {
        width: 45%;
}
.openSidebar{
        transform: scaleX(-1);
        transition: 0.4s ease-in-out;
}
.nav_aside_link {
        display: flex;
        align-items: center;
        padding-block: .4rem;
        color: #3c3d41;
        overflow: hidden;
}
.nav_aside_icon {
        padding-inline: .7rem;
        font-size: 1.2rem;
        padding-top: .3rem;
}
.nav_aside_text {
        font-size: var(--fs-st);
        visibility: hidden;
        opacity: 0;
        transition: all .4s ease-in-out;
}
.scale_side_nav .nav_aside_text {
        visibility: visible;
        opacity: 1;
}
@media(min-width: 50rem) {
        .nav_home_mobile_head .burger {
                display: none;
        }
        .nav_home_mobile_head a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
        }
        .nav_home_content {
                all: unset;
                position: static;
                display: inline;
                /* justify-content: center; */
                width: 100%;
        }   
        .nav_home_mobile_head {
                width: auto;
                display: flex;
                align-items: center;
        }
        .nav_home_navLinks {
                display: flex;
                justify-content: space-between;
                margin-left: auto;
                padding: 0rem;
                margin-top: 0rem;
                width: 75%;
        }
        .nav_home_navLinks li{
                margin-block: 0rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
        }
        .dropdownNav_seller_m {
                display: none !important;
        }
        
        .seller_navLink {
                transition: all .5s ease-in-out;
                border-bottom: 1px solid #ffffff00;
                font-size: var(--fs-st);
        }
        .seller_navLink:hover{
                /* border-bottom: 1px solid var(--gray); */
        }
        .seller_navLink.active {
                /* border-bottom: 1px solid var(--gray); */
                color: var(--main-green);
                font-weight: 500;
        }
        .add_book_link {
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #ffffff00;
                /* margin-top: .3rem; */
        }
        .add_book_link .iconB {
                padding-top: 0.1rem;
                padding-right: 0.4rem;
        }
        .add_book_link span.linkTop {
                font-weight: 500;
            }
        .flexHead {
                display: flex;
                align-items: center;
        }
        .desktop_nav_seller {
                display: block;
                position: absolute;
                width: 100%;
                min-width: 12rem;
                background-color: #fff;
                border: 1px solid var(--gray);
                padding: 0.3rem 1rem;
                transform: translateX(-20%);
        }
        .desktop_nav_seller li {
                padding: .3rem;
        }
        .mobile_seller {
                display: none !important;
        }
        .dropdownNav_seller span{
                cursor: default;
        }
        .desktop {
                display: block;
        }
        .desktop .linkTop , .desktop .dropdownNav-item{
                font-size: 14px;
                font-weight: 500;
        }

        .switch_to_buying{
                padding: 0.4rem 0.7rem;
                background-color: #055c9d;
                color: #fff;
                font-size: .8rem;
                font-weight: 300;
                display: flex;
                text-wrap: nowrap;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                font-family: 'Montserrat', sans-serif;
        }

        .icon_shift{
                font-weight: bold;
                font-size: 1rem;
                margin-right: 5px;
                display: none;
        }
        .icon_shift_down{
                transform: scaleY(-1);
                display: initial;
                transition:  .4s ease-in-out ;
        }
        .switch_text{
                font-weight: 500;
                font-family: 'Montserrat', sans-serif;
        }

        .switch_to_buying{
                padding: .7rem;
                background-color: #055C9D;
                color: #fff;
                font-size: .9rem;
                font-weight: 900;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
        }

        .icon_shift{
                font-weight: bold;
                font-size: 1rem;
                margin-right: 5px;
        }
        
        .switch_text{
                font-weight: 500;
        }
        /* =========================== NAV SELLER ASIDE ================  */
        .seller_nav_aside {
                display: none;
        }
        .padding_icon{
                padding-bottom: 0.45rem;
        }
}

@media(max-width: 50rem){
        .dropdownNav_seller_m {
                display: block;
        }  
}
@media (min-width: 800px) and (max-width: 900px){

        .desktop:hover .switchToBuyingMsg{
                display: block;
                transform: translateX(-110px) translateY( 50px);
                transition: 0.4s ease-in-out;
        }
        .icon_shift{
                font-size: 1.2rem;
                display: initial;
        }
        .icon_shift_down{
                display: initial;
                
        }
        .switch_text{
                display: none;
        }
        .switchToBuyingMsg{

                text-wrap: nowrap;
                font-size: .9rem;
                font-weight: 400;
                z-index: 1500;
                background-color: #f0f0f0;
                color: rgba(90, 90, 90, .9);
                padding: .7rem 1rem;
                border-radius: 10px;
        }
        
}
@media (min-width: 800px) and (max-width: 1130px){
        .nav_home_content{
            width: auto;
            min-width: 95%;
            position: absolute;
            left: 0;
        }
        .nav_home_navLinks li a{
                font-size: 13px;
        }
        .desktop .linkTop{
                font-size: 13px;
        }
        .switch_text{
                font-size: 14px;
                font-weight: 400;
        }
    }
