/* Conteneur principal */
.pdf-viewer-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100vh;
    overflow: hidden;
    height: 100vh;
}

/* Conteneur des miniatures */
.thumbnails-container {
    width: 150px;
    height: 100%;
    padding: 10px;
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.thumbnails-container img {
    width: 100%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
}

.thumbnails-container img:hover {
    border-color: #001489;
}

.thumbnail.active {
    border-color: #ff5733;
}

/* Conteneur du document */
.document-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow: hidden;
    overflow: hidden;
}

/* Barre de navigation */
.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px 20px;
    border-bottom: 2px solid #ddd;
    width: 100%;
}

.page-info {
    font-weight: bold;
}

/* Boutons de navigation */
.nav-button {
    background: #001489;
    color: white;
    border: none;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
}

.nav-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.nav-button:hover {
    background: #0036a3;
}

/* Affichage PDF */
.pdf-document-wrapper {
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 10px;
    flex: 1;
    overflow: auto;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.pdf-page {
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

/* Mode colonne sur mobile */
@media (max-width: 768px) {
    .pdf-viewer-container {
        flex-direction: column;
        height: auto;
    }

    /* Miniatures en mode carrousel */
    .thumbnails-container {
        width: 100%;
        height: auto;
        border-bottom: 2px solid #ddd;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 5px;
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .thumbnails-container img {
        width: 60px;
        height: auto;
        flex-shrink: 0;
    }

    /* Barre de navigation plus compacte */
    .navigation-bar {
        flex-wrap: wrap;
        gap: 5px;
        padding: 8px;
    }

    .nav-button {
        flex: 1;
        font-size: 14px;
        padding: 6px 8px;
    }

    .document-container {
        width: 100%;
        padding: 5px;
    }

    .pdf-document-wrapper {
        padding: 5px;
        max-height: 70vh;
    }

    .pdf-page {
        width: 100%;
        height: auto;
    }
}

