:root {
        --background: #FFFFFF;
        --primary: #92AAC3;
        --secondary: #486584;
        --text: #000000;
        --border: #E0E0E0;
        --hover: #D0D7DF;
    }
    
    /* * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    } */
    /* .container_profile{
        max-width: 1200px;
        margin: 0 auto;
        padding: 16px;

    } */

    .profile{
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        background-color: #fff;
        color: var(--text);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f6f8fb;
        padding: 2rem;

    }
    
    .container-body {
        /* max-width: 800px; */
        width: 100%;
        background-color: var(--background);
        border-radius: 8px;
        /* display: flex; */
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 16px;

    }
    
    .bloc {
        display: flex;
        align-items: center;
        
        justify-content: space-between;
    
    }
    .info-bloc{
        /* gap: 15px; */
        padding: 1rem;
        border: 1px solid rgba(128, 128, 128, 0.3);
        border-radius: .5rem;
    }
    .left-bloc{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: .3rem;
        height: 100%;
    }
    .left-bloc .profile-info h1{
        text-align: center;
        display: inline-flex;
        align-items: center;
    }
    
    .profile-pic {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
    }
    
    .profile-info h1 {
        font-size: 1.5em;
        margin-bottom: 5px;
        color: #1e1e1e;
        font-weight: bold;
    }
    
    .section {
        margin-top: 20px;
    }
    
    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 10px;   
    }

    .section-header h2{
        font-weight: bold;
    }
    .container-body .left-bloc .initials{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
    }
    .container-body .left-bloc .initials p{

        font-size: 2.5rem;
        font-weight: 600;
        color: #fff;
    }
    
    .row{
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        padding-inline: .7rem;
    }
    .row .info label{
        font-size: 1.2rem;
        /* text-align: center; */
        font-weight: lighter;
        color:  rgba(87, 85, 85, 0.9);;
    }
    
    .row .info h3{
        font-weight: 400;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
  .bio{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .info-bloc .content .row .bio .bio_input{
        text-align: center;
        white-space-collapse: collapse;
        text-wrap: wrap;
    }
    .btn {
        padding: 5px 10px;
        border-radius: 20px;
        border: 2px solid var(--primary);
        background-color: var(--background);
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
    }
    
    .btn.edit:hover,
    .btn.add:hover {
        background-color: var(--background);
        color: #000080;
        font-weight: 500;

    }
    
    .btn.edit {
        color: var(--secondary);
        border-color: var(--secondary);
        color: #fff;
        background-color: #000080;
        display: none;
    }
    
    .btn.add {
        color: var(--primary);
        border-color: var(--primary);
    }
    
    .btn.delete {
        border: none;
        background: none;
        color: #888;
        cursor: pointer;
        font-size: 1.2em;
    }
    
    .btn.delete:hover {
        color: red;
    }

    .profile_title {
        width: 100%;
        text-align: center;
        text-transform: capitalize;
    }
    
    .user_profile{
        padding-top: 2rem;
    }
    .no-books{
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-wrap: wrap;
        background-color: #000080;
        width: auto;
        margin: 1rem;
    }
    .no-books p{
        text-align: center;
        padding: .7rem;
        padding-bottom: 1.6rem;
        color: #fff;
        margin-top: .8rem;
        font-size: 1.1rem;
    }
    .add-books{
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f6f8fb;
        padding-inline: .8rem;
        padding-top: 0.2rem;
        padding-bottom: .2rem;
        height: 2.4rem;
    }

    .add-books:hover{
        background-color: #D0D7DF;
        cursor: pointer;
    }

    .add-books p{
        color:#000080;
        font-size: 1rem;
    }
    .title_profile{
        font-weight: 600;
        font-size: 1.8rem;

    }
    /* Responsiveness */
    @media (max-width: 600px) {
        .left-bloc{
            margin: 0;
        }
        .profile{
            padding: .3rem;
        }
        .container-body{
            padding: 8px;
        }
        .profile-pic{
            width: 3.5rem;
            height: 3.5rem;
        }
        .bloc {
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
        }
        .info-bloc:nth-child(1){
            padding: .4rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .edit_btn{
            display: flex;
            justify-content:end;
            float:inline-end;
            align-items: center;
        }
        .profile-info h1 {
            font-size: .9em;
            margin-bottom: 5px;
            color: #1e1e1e;
            font-weight: bold;
        }
        .section-header h2 {
            font-size: .7em;
            font-weight: 600;
        }
        .row .info label{
            font-size: .6rem;
            /* text-align: center; */
            font-weight: lighter;
            color:  rgba(87, 85, 85, 0.9);;
            text-wrap: nowrap;
        }
        .info-bloc .content .row{
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            justify-content: flex-start;
            padding-inline: .5rem;
            gap: 1rem;
    
        }
        .row .info h3{
            font-size: .7rem;
            text-align: left;
        }
    
        .info-bloc .content .grid{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        .profile_title {
            width: auto;
            margin-left: 3rem;
            text-align: left;
        }
        .add-books{
            padding: .4rem;
        }
        .add-books p{
            color:#000080;
            font-size: .9rem;
        }
    }
