.seller_pages_with_nav {
    background-color: #fff;
    padding-top: 5rem;
}

.seller_dashboard_grid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-gap: 1rem;
    padding-block: 2rem;
    position: relative;
}

.seller_dashboard_section {
    grid-column: auto / span 12;
    width: 100%;
    /* border: 1px solid var(--gray); */
}

.description {
    font-weight: 400;
}

/* =============== FIRST SECTION ======================= */
.seller_profile_card {
    width: 100%;
    border: none;
    padding: 1rem;
    border-radius: 8px;
    background: #fff;
    /* box-shadow: 0 0 10px 5px rgba(200, 200, 200, .3); */
    border: 1px solid var(--gray);
}

.dashboard_seller_leftmain .profilePic {
    overflow: hidden;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: auto;
}

.top_bloc {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(10, 10, 10, .8);
}

.ids {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.seller_profile_card .name {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: start;
    margin-left: 0.8rem;
}

.seller_profile_card .email {
    font-size: .8rem;
    font-weight: 400;
    text-align: start;
    margin-left: 0.8rem;
}

.profilePic {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top_bloc .profilePic img {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    height: 100% !important;
    transform: translate(-50%, -50%);
}

.seller_profile_pic_update {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    opacity: 0;
}

.profile-img {
    width: 3rem;
    height: 3rem;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.statistics {
    text-align: center;
    font-size: var(--fs-st);
    color: rgba(0, 0, 0, 0.7);
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    left: -5px;
    right: -5px;
}

.statistics .title {
    font-size: 0.8rem;
}

.parameter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: .3rem;
}



.iconProp {
    /* text-align: center; */
    align-content: center;
    font-size: 1rem;
    font-weight: 600;
    padding-right: .4rem;
}
.iconBook{
    color: rgb(4, 138, 4);
    font-size: 1rem;
}
.iconCart{
    /* background-color: red; */
    color: red;
}
.star_icon {
    color: rgb(214, 151, 14);
}

.property {
    font-weight: 500;
    font-size: 0.8rem;
    /* text-wrap: nowrap; */
}

.parameter_value {
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgba(200, 200, 200, .3);
    padding: .08rem;
    padding-inline: .4rem;
    border-radius: 10px;
}

.edit_btn_dashboard {
    text-align: end;
    margin-top: 1.5rem;
    color: #fff;
    background-color: rgb(10, 189, 135);
    padding-top: .2rem;
    padding-bottom: .2rem;
    padding-inline: .6rem;
    border-radius: 40px;
    font-size: .8rem;
    width: 4rem;
    margin-left: auto;
}

.edit_profile {
    display: flex;
    justify-content: end;
}

.edit_btn_dashboard:hover {
    background-color: rgb(5, 58, 42);
    color: #fff;
    /* transition: all .3s ease-in-out; */
    /* border: 1px solid #000080; */
}

.edit_profile:hover {
    color: #fff;
}
.parameter{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: .3rem;
}



.iconProp{
    /* text-align: center; */
    align-content: center;
    font-size: 1rem;
    font-weight: 600;
    padding-right: .4rem;
}
.star_icon{
    color: rgb(214, 151, 14);
}
.property{
    font-weight: 500;
    font-size: 0.8rem;
    /* text-wrap: nowrap; */
}
.parameter_value{
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgba(200, 200, 200, .3);
    padding: .08rem;
    padding-inline: .4rem;
    border-radius: 10px;
}
.edit_btn{
    text-align: end;
    margin-top: 1.5rem;
    color: #fff;
    background-color: rgb(10, 189, 135);
    padding-top: .2rem;
    padding-bottom: .2rem;
    padding-inline: .6rem;
    border-radius: 40px;
    font-size: .8rem;
    width: 4rem;
    margin-left: auto;
}
.edit_profile{
    display: flex;
    justify-content: end;
}
a.edit_btn:hover{
    background-color: rgb(5, 58, 42);
    /* transition: all .3s ease-in-out; */
}
.edit_profile:hover{
    color: #fff;
    background-color: rgb(5, 58, 42);
}

.seller_chat_minor,
.dashboard_seller_notification {
    border: 1px solid var(--gray);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 2rem;
    cursor: pointer;
}

.seller_chat_minor .share_shop span {
    background-color: rgba(120, 120, 120, .8);
    padding: .2rem;
    padding-inline: .3rem;
    text-align: center;
    border-radius: 10%;
}

.seller_chat_minor .share_shop .share_icon {
    color: #fff;
}

.seller_chat_minor .share_links li {
    font-size: var(--fs-st);
    color: #1177ff;
    font-weight: 700;
    padding: 0.4rem 0.5rem;
    margin-bottom: 0.3rem;
    transition: all .1s ease-in;
    background-color: transparent;
}

.seller_chat_minor .share_links li a {
    font-weight: 500;
}

.d_flex_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
}

.seller_chat_minor .share_links li:hover {
    background-color: #f2f2ff;
    border-radius: 20px;
}

.seller_chat_minor .share_links .copy_store {
    background-color: #f8f8f8;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid var(--gray);
}

.copy_store span {
    font-weight: 400;
    color: #4b4b4b;
}

.seller_chat_minor .share_links .copy_store:hover {
    background-color: #f1f1f1;
}

.dashboard_seller_notification {
    margin-top: 0;
}

.dashboad_seller_main {
    padding: 1rem;
    padding-top: 0;
    position: static;
    min-height: 70vh;
    margin-top: 0;
}

.dashboard_seller_notification .title {
    text-align: center;
}

hr {
    width: 100%;
    height: 0.1rem;
    background-color: rgba(120, 120, 120, .8);
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.dashboard_seller_notification .title{
    text-align: center;
}
hr{
    width: 100%;
    height: 0.1rem;
    background-color: rgba(120, 120, 120, .8);
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.notification_item {
    border-bottom: 0.5px solid #eee;
    cursor: default;
    margin-block: 0.5rem;
    font-size: var(--fs-st);
    position: relative;
}

.notification_item .notification_title {
    font-weight: 600;
    margin-top: 0.3rem;
    font-size: 0.9em;
}

.notification_item .notification_desc {
    font-size: 0.85em;
    width: 100%;
    position: relative;
    height: 2rem;
    overflow: hidden;
}

.notification_item .desc_text {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2rem;
}

.notification_item .notification_time {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.8em;
    color: #777;
    background-color: #fff;
    padding-left: 0.5rem;
}

.switch_dashboard_page {
    text-align: center;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* ================== SECOND SECTION ======================= */
.seller_shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #4631ff00;
    display: none;
    z-index: 2;
}

.showSellerShadow {
    display: block;
}

.bookList {
    margin-block: 2rem;
}

.seller_books_grid {
    display: grid;
    grid-gap: 1rem;
}

.bookList .book {
    border: 1px solid var(--gray);
    padding: 1rem;
    border-radius: 5px;
    font-size: var(--fs-st);
    position: relative;
    background-color: #fff;
    flex-direction: column;
}

.dashboad_seller_main .book_title {
    font-size: 0.95rem;
    text-transform: capitalize;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}

.dashboad_seller_main .book_title:hover {
    color: salmon;
}

.dashboad_seller_main .book_head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.book_menu {
    margin: 0.3rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.book_menu_dropdown {
    width: 100%;
    min-width: 10rem;
    background-color: #fff;
    border: 1px solid var(--gray);
    border-radius: 5px;
    position: absolute;
    font-weight: 400;
    overflow: hidden;
    margin-top: 0.5rem;
    transform: translateX(-80%);
}

.book_menu_item {
    width: 100%;
    padding: 0.4rem;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
}

.book_menu_item:hover {
    background-color: #9292921c;
}

.delete_book {
    color: tomato;
}

.delete_book:hover {
    background-color: rgba(255, 99, 71, 0.274);
}

.book_menu_dropdownbtn {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #9292922f;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: background 0.2s ease-in-out;
}

.seller_book_tags {
    margin-block: 0.7rem;
}

.seller_books_grid .book_tag {
    display: inline;
    border-radius: 20px;
    margin-right: 3rem;
    padding: 0.3rem 0.7rem;
    font-size: 0.6rem;
}

.seller_books_grid .pending {
    background-color: yellow;
}

.seller_books_grid .active {
    background-color: #039d03;
    font-weight: 400;
    font-size: 0.7rem;
    color: white;
}

.seller_books_grid .reject {
    background-color: rgb(207, 26, 101);
}

.book_menu_dropdownbtn:hover {
    background-color: #92929283;
}

.dashboard_chat_fixed {
    position: relative;
}

/* ============= MODAL NOTIFY ============== */
.book_modalWrapper {
    position: fixed;
    z-index: 20;
    bottom: 0;
    left: 50%;
    transform: translateX(calc(-50% + 1rem));
}

.book_notify_modal {
    background-color: #fff;
    width: clamp(18rem, 50vw, 30rem);
    margin: auto;
    box-shadow: 0px 0px 1px 2px #cccccc46;
    border-radius: 4px;
    font-size: 0.85rem;
    padding: 0.2rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.book_notify_modal span {
    padding-top: 0.3rem;
}

.book_notify_modal .checkmark_book {
    color: var(--main-green);
}

.book_notify_modal.book_deleteModal button,
.add_to_cart_modal_inner button {
    background-color: tomato;
    margin: 0px;
}

.modal_notify_text {
    display: inline;
    margin-right: 1rem;
}

/* =================================== */


.my_seller_book_filterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.dashboard_search_filter_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.6rem;
}
.dashboard_search_filter_flex .search_seller {
    width: 65%;
}
.dashboard_search_filter_flex .dashboard_filter {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_seller_book_filterWrapper .search_sellerbook_filter input {
    padding: 0.3rem;
    font-size: 0.85em;
    font-family: 'Montserrat', sans-serif;
    width: 120%;
}
.my_seller_book_filterWrapper .search_sellerbook_filter input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: gray;
    font-weight: 400;
}

.dashboard_search_filter_flex .dashboard_filter .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
    width: 100%;
    font-size: 0.8rem !important;
}

.search.search_seller input {
    width: 100%;
    background-color: transparent;
    border: none;
    padding-left: 1rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.search.search_seller input::placeholder {
    font-weight: 400;
}

.searchIcon {
    background-color: #002b8c;
    color: white;
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 40px;
    padding: 5px 14px;
    justify-items: center;
    /* margin-top: 2px; */
    cursor: pointer;
    margin-left: 6px;
    position: absolute;
    right: 0;
    width: auto;
}

/* .searchIconDashboard{

} */
.dashboard_filter .filter{
    margin: 0px !important;
}
.dashboard_filter .filter select{
    width: 100%;
    border-radius: 0px;
}
.search_seller {
    padding-top: 1px;
}


.my_seller_book_filterWrapper .filter_by_btn {
    margin-top: 0px;
    color: #808080;
    padding: 0.2rem 0.7rem;
    font-weight: 400;
    background-color: #ebebeb;
    text-transform: lowercase;
    transition: all 0.3s ease-in-out;
}

.my_seller_book_filterWrapper .filter_by_btn.filteringByActive {
    color: #ebebeb;
    background-color: #000080;
    font-weight: 400;
}

.no_book_to_show,
.loading_page_loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.048);
    flex-direction: column;
    border-radius: 4px;
}

.loading_page_loader svg {
    width: 4rem;
}

.loading_page_loader svg * {
    fill: #000;
}

.no_book_to_show {
    padding-block: 2rem;
}

.no_book_to_show div {
    margin-top: 1rem;
    font-size: 0.9em;
    font-weight: 300;
}

.no_book_to_show a {
    color: var(--main-color);
    text-decoration: underline;
    text-transform: uppercase;
}


@media (min-width: 50rem) {
    .book_notify_modal {
        padding: 0.5rem;
        font-size: 0.9rem;
    }
}

@media(max-width: 75rem) {
    .top_bloc {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .seller_profile_card{
        width: 100%;
}
    .seller_dashboard_grid{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        width: 100%;
        gap: 0;
    }
    .seller_profile_card .name{
        text-align: center;
    }

}

@media (min-width: 800px) {
    .seller_dashboard_grid {
        padding-block: 2rem;
    }

    .seller_dashboard_section {
        position: sticky;
        /* top: 15%; */
        grid-column: auto / span 3;
        height: auto;
    }

    .dashboard_seller_leftmain {
        position: sticky;
        /* top: 15%; */
        max-height: 40rem;
        max-width: 18rem;
        grid-column: auto / span 5;
        margin-left: auto;
    }

    .seller_dashboard_main {
        margin-top: -1rem;
    }

    .dashboad_seller_main {
        grid-column: auto / span 10;
        position: static;
        /* min-height: calc(100vh - 8rem); */
        margin-right: auto;
    }

    .dashboard_chat_fixed {
        min-height: calc(100vh - 8rem);
    }

    .dashboard_seller_leftmain .profilePic {
        width: 3rem;
        height: 3rem;
    }

    .seller_books_grid {
        grid-template-columns: repeat(3, 1fr);
        margin-top: -1.5rem !important;
    }

    .seller_mybooks_page .user_main_grid .book {
        width: 100%;
    }

    .statistics .parameter .text_and_icons {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 50rem) {
    .seller_pages_with_nav {
        width: calc(96% - 1rem);
        margin: auto;
        margin-left: calc(2rem);
        padding-top: 5rem;
    }

    /* .seller_chat_minor, */
    .dashboard_seller_notification {
        display: none;
    }

    .seller_mybooks_page .main_user_section {
        margin: 0rem !important;
        width: 100% !important;
        padding: 0px !important;
    }

    .my_seller_book_filterWrapper .filter_by_btn {
        margin-right: 0.4rem;
        margin-bottom: 1rem;
        padding-inline: 0.5rem;
    }

    .seller_mybooks_page .user_main_grid .book {
        width: 250px;
    }

    .statistics {
        flex-direction: column;
        gap: 1rem;
    }

    .parameter {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background-color: rgba(220, 220, 220, .2);
        border-radius: 5px;
    }

    .text_and_icons {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .parameter_value {
        margin-left: auto;
    }

    .edit_btn_dashboard {
        text-align: center;
        /* width: auto; */
    }
        .parameter_value {
            margin-left: auto;
        }
        .edit_btn_dashboard{
            text-align: center;
        }
}

@media (max-width: 599px) {
        .dashboard_seller_leftmain{
            height: auto;
        }
}

.my_book_item{
    display: flex;
    justify-content: center;
}
@media(min-width: 800px ) and (max-width: 1000px){
    .seller_profile_card{
        padding: .5rem;
    }
    /* .iconProp{
        font-size: .8rem;
        font-size: 400;
    } */
    .dashboad_seller_main{
        padding: .5rem;
    }
    .iconItem{
        font-size: 1.5rem;
    }
    .property{
        display: none;
    }

}