.country-phone-input {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.country-wrapper {
    width: 40%;
    position: relative;
}

.input-field input {
    width: 100%;
    height: 3rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.9rem;
    outline: none;
}


.input-field input:focus {
    border-color: #000080;
}

.country-list-suggestions {
    position: absolute;
    top: 110%;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
}

.country-list-item {
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s;
}

.country-list-item:hover {
    background: #f0f0f0;
}

.country-list-item .icon img {
    width: 20px;
    margin-right: 10px;
}

.phone-input {
    width: 100%;
    position: relative;
}

.preview-country-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.preview-country-icon img {
    width: 25px;
    height: auto;
}
.seller-container input[type="text"]{
    width: 100%;
    height: 1.5rem;
    padding: 1.5rem 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    font-weight: 400;
    font-size: 1rem;
}
.input-field input::placeholder {
    font-size: 0.9rem;  
    color: #888;   
    font-weight: 400;   
}

